import { ContactUs } from './bottom-info.styles';

const BottomInfo = () => {
  return (
    <div>
      <ContactUs>Contact with us here:📞XXXXXXXX</ContactUs>
      <ContactUs>
        <h2>@啊对对对队 </h2>{' '}
        <h3>
          built with 💖 by <a href='https://github.com/Nagi-ovo'>Nagi</a>
        </h3>
      </ContactUs>
    </div>
  );
};

export default BottomInfo;
