import styled from 'styled-components';

export const ContactUs = styled.div`
display:flex;
  font-size: 25px;
  font-family: 
  align-content: center;
  justify-content: center;
  margin-top:100px;
  h2{
        margin-left:30px;
        font-size:30px;

  }
  h3{
    margin-top:40px;
    font-size:20px;
    margin-left:20px;

    a {
    color: blue;
    text-decoration: underline;
  }
  }
`;
